import './Editor.css';
import EditerHeader from '../editor-header/EditerHeader';
import React, { useState, useRef, useEffect } from 'react';
import { useJsonFormatter } from '../../customhooks/useJsonFormatter';
import { useSearchText } from 'customhooks/useSearchText';
import { useXmlFormatter } from 'customhooks/useXmlFormatter';
import { InputTextFormatType } from 'constants/InputTextFormatType';
import {formatXML, xmlToObject,jsonToXml} from 'utils/xmlParser'

const Editor = () => {
  const [inputText, setInputText] = useState('');
  const [selectedFormat, setSelectedFormat] = useState(InputTextFormatType.JSON);
  const [formattedText, setFormattedText] = useState({content:'',format:0});
  const [errorMessage, setErrorMessage] = useState('');
  const formattedJsonRef = useRef(null);
  const textAreaRef = useRef(null);
  const [fontSize, setFontSize] = useState(18);
  const { searchText, handleSearch, handleNextFocus, handlePrevFocus } = useSearchText(formattedText.content, formattedJsonRef);
  const { getHighlightedTextForJSON } = useJsonFormatter(searchText);
  const { getHighlightedTextXML, removeXmlFormatting } = useXmlFormatter(searchText);
  const inputTextFormat = useRef(null)
  

  useEffect(()=>{
    if(inputText){
      formateInputText()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedFormat,inputText])


 

  const handleFontSizeChange = (event) => {
    const newFontSize = parseInt(event.target.value);
    setFontSize(newFontSize);
  };

  const handleFormatChange = (event) => {
    // setFormattedText('')
    setSelectedFormat(parseInt(event.target.value));

  };


  const handleformatTextClick = () => {
    const data = textAreaRef.current.value
    if(data){
    setErrorMessage('')
    inputTextFormat.current = identifyFormat(data)
    setSelectedFormat(inputTextFormat.current)
    setInputText(data)
    }else{
      setErrorMessage("Please enter or paste XML or JSON text!")
    }
  };

  function identifyFormat(text) {
    try {
      // Try to parse as JSON
      JSON.parse(text);
      return InputTextFormatType.JSON;
  } catch (e) {
      // If JSON parsing fails, try XML parsing
      try {
          let parser = new DOMParser();
          let xmlDoc = parser.parseFromString(text, "application/xml");
          if (xmlDoc.getElementsByTagName("parsererror").length === 0) {
              return  InputTextFormatType.XML;
          }
      } catch (e) {
          setErrorMessage('This not valid JSON or XML')
          // If both JSON and XML parsing fail
          return 'Unknown';
      }
  }
  }

  const formateInputText = () => {
    try {

      if (inputTextFormat.current===InputTextFormatType.XML && selectedFormat===InputTextFormatType.XML) {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(removeXmlFormatting(inputText), 'application/xml');
        setFormattedText({content:xmlDoc,format:InputTextFormatType.XML})

      } else if(inputTextFormat.current===InputTextFormatType.JSON && selectedFormat===InputTextFormatType.JSON) {
        const parsedJson = JSON.parse(inputText);
        const formatted = JSON.stringify(parsedJson, null, 2);
        setFormattedText({content:JSON.parse(formatted),format:InputTextFormatType.JSON})

      }else if(inputTextFormat.current===InputTextFormatType.XML && selectedFormat===InputTextFormatType.JSON) {
        
        const obj = JSON.parse(JSON.stringify(xmlToObject(inputText), null, 2));
        setFormattedText({content:obj,format:InputTextFormatType.JSON})
      }else if(inputTextFormat.current===InputTextFormatType.JSON && selectedFormat===InputTextFormatType.XML) {
        const parser = new DOMParser();
        const xmlstring = jsonToXml(JSON.parse(inputText));
        const xmlDoc = parser.parseFromString(xmlstring, 'application/xml');
        setFormattedText({content:xmlDoc,format:InputTextFormatType.XML})
      } else {
        setFormattedText({content:'',format:0})
        setErrorMessage('This not valid JSON or XML')
      }

    } catch (error) {
      console.log(error);
      setErrorMessage('Invalid JSON or XML!\n' + error.message);
    }
  }


  const handleCopyText = async () => {
    if(!inputText){
      alert('Please enter the paste the data and format it to copy.');
      return false
    }

    try {
      if (inputTextFormat.current===InputTextFormatType.JSON && selectedFormat===InputTextFormatType.JSON) {
        const parsedJson = JSON.parse(inputText);
        const formatted = JSON.stringify(parsedJson, null, 2);
        await navigator.clipboard.writeText(formatted);
      } else if(inputTextFormat.current===InputTextFormatType.XML && selectedFormat===InputTextFormatType.XML) {
        const formattedXml = formatXML(inputText);
        await navigator.clipboard.writeText(formattedXml);
      }else if(inputTextFormat.current===InputTextFormatType.XML && selectedFormat===InputTextFormatType.JSON) {
       const data=  JSON.stringify(xmlToObject(inputText), null, 2)
       await navigator.clipboard.writeText(data);
      }else if(inputTextFormat.current===InputTextFormatType.JSON && selectedFormat===InputTextFormatType.XML) {
        const xmlstring = formatXML(jsonToXml(JSON.parse(inputText)));
        await navigator.clipboard.writeText(xmlstring);
      }
        alert('Text copied to clipboard!');

    } catch (error) {
      console.error('Failed to copy:', error);
      setErrorMessage('Invalid JSON or XML \n ' + error.message);
    }
  }

  const handleClearData = () => {
    setInputText('');
    setErrorMessage('')
  };

  const handleNewData = () => {
    setInputText('');
    setFormattedText({content:'',format:0});
    setErrorMessage('')
  };


  const renderInputText = () => {

    return <textarea type="text"
      placeholder="Enter or Paste the JSON/XML text here"
      className="textareainput" 
      rows="10"
      ref={textAreaRef} // Assigning the ref to the textarea
    />
  }

  const renderFormatButtons = () => {

    return (
      <div className='buttons-formats-container'>

        {!formattedText.content && (
          <button className="buttonFormat" onClick={handleformatTextClick}>
            Format JSON
          </button>
        )}
        {inputText && !formattedText.content && (
          <button className="buttonClear" onClick={handleClearData}>
            Clear
          </button>
        )}

      </div>
    )
  }

  const renderFormattedText = () => {

    if(formattedText.format===InputTextFormatType.XML&&formattedText.content){
      return (
        <div className='container-formatted-text'>
          <pre
            style={{ fontSize: `${fontSize}px` }}
            ref={formattedJsonRef}

          >
            {getHighlightedTextXML(formattedText.content.documentElement, 0)}
          </pre>
      </div>
    )
    }
    
    if(formattedText.format===InputTextFormatType.JSON&&formattedText.content){

      return (
        <div className='container-formatted-text'>
      
          <pre
            style={{ fontSize: `${fontSize}px` }}
            ref={formattedJsonRef}

          >
            {getHighlightedTextForJSON(formattedText.content, 0)}
          </pre>
  
      </div>
    )
    }

  }

  const renderErrorText = () => {

    return (
      <div className='container-formatted-text'>
        {errorMessage && (
          <pre
            className='error-text'
          >
            {errorMessage}

          </pre>
        )}
      </div>
    )
  }

  return (
    <div className='container-editor'>
      <div className='toolbar-container'>
        <EditerHeader
          fontSize={fontSize}
          copyButtonClick={handleCopyText}
          newButtonClick={handleNewData}
          handleSearch={handleSearch}
          upButtonClick={handlePrevFocus}
          downButtonClick={handleNextFocus}
          handleFontSizeChange={handleFontSizeChange}
          handleFormatChange={handleFormatChange} 
          selectedformat={selectedFormat}
        />
        <div className='header-line' />
      </div>
      {!formattedText.content && renderInputText()}
      {renderFormatButtons()}

      {formattedText.content && renderFormattedText()}
      {errorMessage && renderErrorText()}
    </div>
  )
}

export default Editor