
import './EditerHeader.css';
import { ReactComponent as IconArrowUp } from '../../images/arrow-up.svg';
import { ReactComponent as IconArrowDown } from '../../images/arrow-down.svg';
import { ReactComponent as IconSearch } from '../../images/ic-search.svg';
import SizePicker from '../dropdowns/size-picker/SizePicker';
import FormatPicker from 'component/dropdowns/format-picker/FormatPicker';

const EditerHeader = ({
    newButtonClick, 
    copyButtonClick,
     upButtonClick,
      downButtonClick,
      handleFontSizeChange,
      fontSize,
      handleSearch,
      handleFormatChange,
      selectedformat
    }) => {

    const renderNewBtn =() =>{
        return (<button className='newbutton' onClick={newButtonClick}>New</button>)
    }

    const renderCopyBtn =() =>{
        return (<button  className='copybutton' onClick={copyButtonClick}>Copy JSON / XML</button>)
    }
    const renderSearchBar= () =>{


        return( <div className="search-container">
            <IconSearch/>
            <input type="text" placeholder="Search" className="search-input" onChange={handleSearch} />
          </div>)
    }
    const renderUpBtn =() =>{
        return(
        <button className="upButton" onClick={upButtonClick}>
            <IconArrowUp />
          </button>) 
    }

    const renderDownBtn =() =>{
        return(
            <button className="downButton" onClick={downButtonClick}>
                <IconArrowDown />
              </button>) 
    }


    return (
        <div className='editer-header-container'>
             <div className='header-buttons-container'>
             {renderNewBtn()}
             {renderCopyBtn()}
             </div>
           

            <div className='search-tools-container'>
                {renderSearchBar()}
                {renderUpBtn()}
                {renderDownBtn()}
                <FormatPicker 
                    handleFormatChange={handleFormatChange} 
                    selectedformat={selectedformat}/>
                <SizePicker
                    fontSize={fontSize}  
                    handleFontSizeChange={handleFontSizeChange}/>
            </div>
           
        </div>
        )
}

export default EditerHeader