/* eslint-disable */

export function xmlToObject(xmlString) {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xmlString, "application/xml");

  function parseValue(value) {
    if (value === "true") return true;
    if (value === "false") return false;
    if (value === "null") return null;
    if (/^\d+$/.test(value)) return parseInt(value, 10);
    if (/^\d*\.\d+$/.test(value)) return parseFloat(value);
    return value;
  }

  function parseNode(node) {
    if (!node) {
      console.error("Node is undefined");
      return undefined;
    }
    if (node.nodeType === Node.TEXT_NODE) {
      return parseValue(node.nodeValue.trim());
    }
    
    const obj = {};
    let textContent = "";

    // Add attributes as properties
    if (node.attributes) {
      for (const attr of node.attributes) {
        obj[attr.name] = parseValue(attr.value);
      }
    }

    for (const child of node.childNodes) {
      if (child.nodeType === Node.TEXT_NODE) {
        textContent += child.nodeValue.trim();
      } else if (child.nodeType === Node.ELEMENT_NODE) {
        const key = child.nodeName;
        const value = parseNode(child);
        
        if (obj[key] !== undefined) {
          if (!Array.isArray(obj[key])) {
            obj[key] = [obj[key]];
          }
          obj[key].push(value);
        } else {
          obj[key] = value;
        }
      }
    }

    // If there's only text content and no child elements, return the parsed value
    if (Object.keys(obj).length === 0 && textContent) {
      return parseValue(textContent);
    }

    // If there's both text content and child elements, add parsed text as a property
    if (textContent) {
      obj._text = parseValue(textContent);
    }

    return obj;
  }

  return parseNode(xmlDoc.documentElement);
}

export function jsonToXml(json, rootElementName = 'root') {
  function createXmlNode(key, value) {
    if (typeof value === 'object' && !Array.isArray(value)) {
      return `<${key}>${jsonToXml(value)}</${key}>`;
    } else if (Array.isArray(value)) {
      return value.map(item => createXmlNode(key, item)).join('');
    } else {
      return `<${key}>${value}</${key}>`;
    }
  }

  let xmlString = '';
  for (const key in json) {
    if (json.hasOwnProperty(key)) {
      xmlString += createXmlNode(key, json[key]);
    }
  }

  return `<${rootElementName}>${xmlString}</${rootElementName}>`;
}


export function formatXML(xml) {
  // Parse the XML string to a DOM object
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xml, 'application/xml');

  // Serialize the DOM object back to a string with indentation
  const serializer = new XMLSerializer();
  let formatted = serializer.serializeToString(xmlDoc);
  
  // Beautify the formatted string
  const reg = /(>)(<)(\/?)/g;
  const wsexp = / *(.*) +\n/g;
  const contexp = /(<.+>)(.+\n)/g;

  formatted = formatted.replace(reg, '$1\n$2$3');
  formatted = formatted.replace(wsexp, '$1\n');
  formatted = formatted.replace(contexp, '$1\n$2');

  let pad = 0;
  let formattedXml = '';
  formatted.split('\n').forEach((node) => {
    let indent = 0;
    if (node.match(/.+<\/\w[^>]*>$/)) {
      indent = 0;
    } else if (node.match(/^<\/\w/) && pad !== 0) {
      pad -= 1;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    } else if (node.match(/^<\w[^>]*[^\/]>.*$/)) {
      indent = 1;
    } else {
      indent = 0;
    }

    let padding = '';
    for (let i = 0; i < pad; i++) {
      padding += '  ';
    }

    formattedXml += padding + node + '\n';
    pad += indent;
  });

  return formattedXml.trim();
}