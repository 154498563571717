import './SizePicker.css';
import { ReactComponent as IconFontSize } from '../../../images/font-size-icon.svg';
import { ReactComponent as IconDown } from '../../../images/arrow_drop_down.svg';

const SizePicker = ({handleFontSizeChange,fontSize}) => {
    return (
        <div className="dropdown-container">
            <IconFontSize className="icon icon-font-size" />
            <select value={fontSize} className="dropdown-select" onChange={handleFontSizeChange}>
                <option value={8}>8px</option>
                <option value={12}>12px</option>
                <option value={14}>14px</option>
                <option value={16}>16px</option>
                <option value={18}>18px</option>
                <option value={22}>22px</option>
                <option value={24}>24px</option>
            </select>
            <IconDown className="icon icon-down" />
        </div>
    )
}

export default SizePicker