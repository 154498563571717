// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MenuBar from './component/header/Header';
import './App.css';
import Home from './page/home/Home';
import { KEYWORDS } from 'constants/Keyword';

function App() {
  return (
    <html lang="en">
      <head>

        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6523892810347543"
          crossorigin="anonymous"></script>
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6523892810347543" crossorigin="anonymous"></script>

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap" rel="stylesheet"></link>

      </head>
      <Router>

        <div className="container">
          <MenuBar />
          <div className="subcontainer">

            <Routes>
              <Route path={`/${KEYWORDS.JSON_Formatter}`} element={<Home keyword={KEYWORDS.JSON_Formatter} />} />
              <Route path={`/${KEYWORDS.JSON_Parser}`} element={<Home keyword={KEYWORDS.JSON_Parser} />} />
              <Route path={`/${KEYWORDS.JSON_Beautify}`} element={<Home keyword={KEYWORDS.JSON_Beautify} />} />
              <Route path={`/${KEYWORDS.JSON_Validator}`} element={<Home keyword={KEYWORDS.JSON_Validator} />} />
              <Route path={`/${KEYWORDS.JSON_Minify}`} element={<Home keyword={KEYWORDS.JSON_Minify} />} />
              <Route path={`/${KEYWORDS.JSON_PrettyPrint}`} element={<Home keyword={KEYWORDS.JSON_PrettyPrint} />} />
              <Route path={`/${KEYWORDS.JSON_Analyzer}`} element={<Home keyword={KEYWORDS.JSON_Analyzer} />} />
              <Route path={`/${KEYWORDS.JSON_Linter}`} element={<Home keyword={KEYWORDS.JSON_Linter} />} />
              <Route path={`/${KEYWORDS.JSON_Converter}`} element={<Home keyword={KEYWORDS.JSON_Converter} />} />
              <Route path={`/${KEYWORDS.JSON_Diff}`} element={<Home keyword={KEYWORDS.JSON_Diff} />} />
              <Route path={`/${KEYWORDS.JSON_Schema_Validator}`} element={<Home keyword={KEYWORDS.JSON_Schema_Validator} />} />
              <Route path={`/${KEYWORDS.JSON_ONLINE_EDITOR}`} element={<Home keyword={KEYWORDS.JSON_ONLINE_EDITOR} />} />
              <Route path={`/${KEYWORDS.JSON_ONLINE_FREE_FORMATTER}`} element={<Home keyword={KEYWORDS.JSON_ONLINE_FREE_FORMATTER} />} />
             
             
              <Route path={`/${KEYWORDS.XML_Formatter}`} element={<Home keyword={KEYWORDS.XML_Formatter} />} />
              <Route path={`/${KEYWORDS.XML_Parser}`} element={<Home keyword={KEYWORDS.XML_Parser} />} />
              <Route path={`/${KEYWORDS.XML_Beautify}`} element={<Home keyword={KEYWORDS.XML_Beautify} />} />
              <Route path={`/${KEYWORDS.XML_Validator}`} element={<Home keyword={KEYWORDS.XML_Validator} />} />
              <Route path={`/${KEYWORDS.XML_Minifier}`} element={<Home keyword={KEYWORDS.XML_Minifier} />} />
              <Route path={`/${KEYWORDS.XML_Linter}`} element={<Home keyword={KEYWORDS.XML_Linter} />} />
              <Route path={`/${KEYWORDS.XML_Prettifier}`} element={<Home keyword={KEYWORDS.XML_Prettifier} />} />
              <Route path={`/${KEYWORDS.XML_SchemaValidator}`} element={<Home keyword={KEYWORDS.XML_SchemaValidator} />} />
              <Route path={`/${KEYWORDS.XML_Converter}`} element={<Home keyword={KEYWORDS.XML_Converter} />} />
              <Route path={`/${KEYWORDS.XML_Comparer}`} element={<Home keyword={KEYWORDS.XML_Comparer} />} />
              <Route path={`/${KEYWORDS.XML_Editor}`} element={<Home keyword={KEYWORDS.XML_Editor} />} />
              <Route path="/" element={<Home keyword={KEYWORDS.JSON_Formatter} />} />
              <Route path={`/${KEYWORDS.XML_ONLINE_EDITOR}`} element={<Home keyword={KEYWORDS.XML_ONLINE_EDITOR} />} />
              <Route path={`/${KEYWORDS.XML_ONLINE_FREE_FORMATTER}`} element={<Home keyword={KEYWORDS.XML_ONLINE_FREE_FORMATTER} />} />
             
            </Routes>
          </div>
        </div>

      </Router>
    </html>
  );
}

export default App;
