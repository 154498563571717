export const KEYWORDS = {
    JSON_Formatter: 'JSON-Formatter',
    JSON_Parser: 'JSON-Parser',
    JSON_Beautify: 'JSON-Beautify',
    JSON_Validator: 'JSON-Validator',
    JSON_Minify: 'JSON-Minify',
    JSON_PrettyPrint: 'JSON-PrettyPrint',
    JSON_Analyzer: 'JSON-Analyzer',
    JSON_Linter: 'JSON-Linter',
    JSON_Converter: 'JSON-Converter',
    JSON_Diff: 'JSON-Diff',
    JSON_Schema_Validator: 'JSON-Schema-Validator',
    JSON_ONLINE_EDITOR:'JSON-online-editor',
    JSON_ONLINE_FREE_FORMATTER:'JSON-online-free-formatter',

    XML_Formatter: 'XML-Formatter',
    XML_Parser: 'XML-Parser',
    XML_Beautify: 'XML-Beautify',
    XML_Validator: 'XML-Validator',
    XML_Minifier: 'XML-Minifier',
    XML_Linter: 'XML-Linter',
    XML_Prettifier: 'XML-Prettifier',
    XML_SchemaValidator: 'XML-SchemaValidator',
    XML_Converter: 'XML-Converter',
    XML_Comparer: 'XML-Comparer',
    XML_Editor: 'XML-Editor',
    XML_ONLINE_EDITOR:'XML-online-editor',
    XML_ONLINE_FREE_FORMATTER:'xml-online-free-formatter',
}