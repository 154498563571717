import { InputTextFormatType } from 'constants/InputTextFormatType';
import './FormatPicker.css';
import { ReactComponent as IconDown } from 'images/arrow_drop_down.svg';

const FormatPicker = ({handleFormatChange,selectedformat}) => {
    return (
        <div className="dropdown-container">
        
            <select value={selectedformat} className="format-dropdown-select" onChange={handleFormatChange}>
                <option value={InputTextFormatType.JSON}>JSON</option>
                <option value={InputTextFormatType.XML}>XML</option>
            </select>
            <IconDown className="icon icon-down" />
        </div>
    )
}

export default FormatPicker