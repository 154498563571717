// src/customhooks/useJsonSearch.js
import { useState, useRef, useCallback } from 'react';
import { jsonStyle } from '../constants/EditorTextStyles';

export const useSearchText = (formattedJson, formattedJsonRef) => {
  const [searchText, setSearchText] = useState('');
  const matchedNodeslistRef = useRef([]);
  const posRef = useRef(0);

  const handleSearch = useCallback((e) => {
    const newSearchText = e.target.value;
    setSearchText(newSearchText);
    posRef.current = 0; 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formattedJson]);

  const scrollToMatch = useCallback(() => {

    if (formattedJsonRef.current) {
      const container = formattedJsonRef.current;
      const textNodes = container.querySelectorAll('mark');
      matchedNodeslistRef.current =textNodes
    }

    const matchedNode = matchedNodeslistRef.current[posRef.current];
    if (matchedNode) {
      matchedNodeslistRef.current.forEach(element => {
        if (element === matchedNode) {
          matchedNode.style.backgroundColor = jsonStyle.selectionBackgroundText.color;
          matchedNode.oldTextColor = matchedNode.style.color;
          matchedNode.style.color = 'white';
        } else {
          element.style.color = element.oldTextColor;
          element.style.backgroundColor = 'yellow';
        }
      });

      matchedNode.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNextFocus = useCallback(() => {
    if (posRef.current >= matchedNodeslistRef.current.length - 1) {
      posRef.current = 0;
    } else {
      posRef.current += 1;
    }
    scrollToMatch();
  }, [scrollToMatch]);

  const handlePrevFocus = useCallback(() => {
    if (posRef.current <= 0) {
      posRef.current = matchedNodeslistRef.current.length - 1;
    } else {
      posRef.current -= 1;
    }
    scrollToMatch();
  }, [scrollToMatch]);

  return {
    searchText,
    handleSearch,
    handleNextFocus,
    handlePrevFocus
  };
};