
export const jsonStyle = {
    key: { color: '#1AD243' }, // Key color
    string: { color: '#F06767' }, // String value color
    number: { color: '#509dff' }, // Number value color
    boolean: { color: '#a4a4f5' }, // Boolean value color
    null: { color: '#ff00a3' }, // Null value color
    selectionBackgroundText: { color: '#007bff' }
  };

  export const xmlStyle = {
    tag: {
      color: '#1AD243',
    },
    attribute: {
      color: '#e7d36b',
    },
    attributeValue: {
      color: '#e7d36b',
    },
    string: { color: '#F06767' }, // String value color
    number: { color: '#509dff' }, // Number value color
    boolean: { color: '#a4a4f5' }, // Boolean value color
    null: { color: '#ff00a3' }, // Null value color
  };