import React from 'react';
import './Header.css';
const Header = () => {
  return (
    <div className='main'>
      <h1 className='app-title'>NEOFA</h1>
    </div>
  );
};

export default Header;