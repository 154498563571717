// src/hooks/useJsonFormatter.js
import { useState, useCallback } from 'react';

import MinusButton from 'component/buttons/minusButton/MinusButtons'
import PlusButton from 'component/buttons/plusButton/PlusButtons';
import { jsonStyle } from '../constants/EditorTextStyles';
export const useJsonFormatter = (searchText) => {
  const [isExpand, setExpand] = useState({});

  const updateExpandValue = useCallback((key, newValue) => {
    setExpand((prevValues) => ({
      ...prevValues,
      [key]: newValue,
    }));
  }, []);

  const expandBtn = (key) => {
    return <PlusButton onClick={() => updateExpandValue(key, false)} />;
  };

  const collapseBtn = (key) => {
    return <MinusButton onClick={() => updateExpandValue(key, true)} />;
  };

  const highlightText = useCallback((text) => {
    if (!searchText) return <>{text}</>;
    const regex = new RegExp(`(${searchText})`, 'gi');
    return (
      <>
        {text.split(regex).map((part, index) => (
          regex.test(part) ? <mark key={index}>{part}</mark> : part
        ))}
      </>
    );
  }, [searchText]);

  const generateButton = useCallback((ukey) => {
    return <>{!isExpand[ukey] ? collapseBtn(ukey) : expandBtn(ukey)}</>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpand]);

  const genrateKeyVal = useCallback((ukey, formattedValue, formattedKey = null, collapsedValue) => {
    return (
      <>
        {formattedKey ? (
          <>
            {' '}
            {formattedKey} : {!isExpand[ukey] ? formattedValue : collapsedValue}
          </>
        ) : (
          <>{!isExpand[ukey] ? formattedValue : collapsedValue}</>
        )}
      </>
    );
  }, [isExpand]);

  const getHighlightedTextForJSON = useCallback((jsonObj, parentIndex) => {
    return (
      <span>
        {Array.isArray(jsonObj) ? '[' : '{'}
        {Object.entries(jsonObj).map(([key, value], index, array) => {
          const formattedKey = Array.isArray(jsonObj) ? null : (
            <span style={jsonStyle.key}>{highlightText(JSON.stringify(key))}</span>
          );
          let formattedValue;

          if (typeof value === 'object' && value !== null) {
            formattedValue = getHighlightedTextForJSON(value, `${index}${parentIndex}`);
          } else {
            switch (typeof value) {
              case 'string':
                formattedValue = (
                  <span style={jsonStyle.string}>{highlightText(JSON.stringify(value))}</span>
                );
                break;
              case 'number':
                formattedValue = (
                  <span style={jsonStyle.number}>{highlightText(JSON.stringify(value))}</span>
                );
                break;
              case 'boolean':
                formattedValue = (
                  <span style={jsonStyle.boolean}>{highlightText(JSON.stringify(value))}</span>
                );
                break;
              case 'object':
                formattedValue = (
                  <span style={jsonStyle.null}>{highlightText(JSON.stringify(value))}</span>
                );
                break;
              default:
                formattedValue = <span>{JSON.stringify(value)}</span>;
                break;
            }
          }
          const collapsedValue = Array.isArray(value) ? '[...]' : '{...}';
          return (
            <div key={`${index}${parentIndex}`} style={{ marginLeft: Array.isArray(jsonObj) ? '20px' : '20px', }}>
              {typeof value === 'object' && value !== null && generateButton(`${index}${parentIndex}`)}
              {genrateKeyVal(`${index}${parentIndex}`, formattedValue, formattedKey, collapsedValue)}
              {index < array.length - 1 ? ',' : ''}
            </div>
          );
        })}
        {Array.isArray(jsonObj) ? ']' : '}'}
      </span>
    );
  }, [highlightText, generateButton, genrateKeyVal]);

  return {
    getHighlightedTextForJSON
  };
};