// src/hooks/useXmlFormatter.js
import { useState, useCallback } from 'react';
import MinusButton from 'component/buttons/minusButton/MinusButtons';
import PlusButton from 'component/buttons/plusButton/PlusButtons';
import { xmlStyle } from '../constants/EditorTextStyles';

export const useXmlFormatter = (searchText) => {
  const [isExpand, setExpand] = useState({});

  const updateExpandValue = useCallback((key, newValue) => {
    setExpand((prevValues) => ({
      ...prevValues,
      [key]: newValue,
    }));
  }, []);

  const expandBtn = (key) => {
    return <PlusButton onClick={() => updateExpandValue(key, false)} />;
  };

  const collapseBtn = (key) => {
    return <MinusButton onClick={() => updateExpandValue(key, true)} />;
  };

  const highlightText = useCallback((text) => {
    if (!searchText) return <>{text}</>;
    const regex = new RegExp(`(${searchText})`, 'gi');
    return (
      <>
        {text.split(regex).map((part, index) => (
          regex.test(part) ? <mark key={index}>{part}</mark> : part
        ))}
      </>
    );
  }, [searchText]);

  const generateButton = useCallback((ukey) => {
    return <>{!isExpand[ukey] ? collapseBtn(ukey) : expandBtn(ukey)}</>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpand]);

  const genrateKeyVal = useCallback((ukey, formattedValue, formattedKey = null, collapsedValue) => {
    return (
      <>
        {formattedKey ? (
          <>
            {' '}
            {formattedKey} : {!isExpand[ukey] ? formattedValue : collapsedValue}
          </>
        ) : (
          <>{!isExpand[ukey] ? formattedValue : collapsedValue}</>
        )}
      </>
    );
  }, [isExpand]);

  const removeXmlFormatting = (xmlString) => {
       // Remove all newlines and extra spaces
       let formattedXml = xmlString.replace(/\s+/g, ' ').trim();
       // Remove spaces between > and <
       formattedXml = formattedXml.replace(/>\s+</g, '><');

    return formattedXml;
  };

  function getStyleForDataType(value) {
    if (value === 'null') return xmlStyle.null;
    if (!isNaN(value) && value.trim() !== '') return xmlStyle.number;
    if (value === 'true' || value === 'false') return xmlStyle.boolean;
    return xmlStyle.string; // Default to string style
  }

  const getHighlightedTextXML = useCallback((xmlNode, parentIndex) => {
    console.log(xmlNode)
    return (
      <span>
        {xmlNode.nodeType === 1 && <span style={xmlStyle.tag}>&lt;{highlightText(xmlNode.nodeName)}</span>}
        {Array.from(xmlNode.attributes || []).map((attr) => (
          <span key={attr.name} style={xmlStyle.attribute}>
            {' '}
            {highlightText(attr.name)}="<span style={xmlStyle.attributeValue}>{highlightText(attr.value)}</span>"
          </span>
        ))}
        {xmlNode.nodeType === 1 && <span style={xmlStyle.tag}>&gt;</span>}

        {Array.from(xmlNode.childNodes).map((childNode, index) => {
          const key = `${parentIndex}-${index}`;
          if (childNode.nodeType === 3) { // Text node
            return (
              <span key={key}  style={getStyleForDataType(childNode.nodeValue)}>
                {highlightText(childNode.nodeValue)}
               
              </span>
            );
          } else if (childNode.nodeType === 1) { // Element node
            const collapsedValue ='<...>';
            return (
              <div key={key} style={{ marginLeft: '20px' }}>
                {generateButton(key)}
                {genrateKeyVal(key, getHighlightedTextXML(childNode, key), null, collapsedValue)}
              </div>
            );
          }
          return null;
        })}

        {xmlNode.nodeType === 1 && (
          <span style={xmlStyle.tag}>
            &lt;/{highlightText(xmlNode.nodeName)}&gt;
          </span>
        )}
      </span>
    );
  }, [highlightText, generateButton, genrateKeyVal]);

  return {
    getHighlightedTextXML,
    removeXmlFormatting
  };
};
