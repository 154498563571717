import React from 'react';
import './Home.css';
import FeedbackForm from '../feedback_form/FeedbackForm';
import Editor from '../../component/editor/Editor';
import { Helmet } from 'react-helmet';
import { homeDatalist } from 'constants/HomePageData';

const Home = ({ keyword }) => {

  const homeData = homeDatalist.filter(item => item.keyword === keyword)[0]

  // const DonateContainer = ({content}) =>{

  //   return <div className="dontate-container">
  //   <div className="dontate-content-container">
  //     <h2>{content.title}</h2>
  //     <p>{content.content}</p>
  //   </div>
  //   <div className='donate-button-container'>
  //     <button className="donate-button">Donate</button>
  //   </div>

  // </div>
  // }

  const IntroContainer = ({ content }) => {

    return <div className='intro-container'>
      <h1 >{content.title}</h1>
      <p> {content.content} </p>

    </div>
  }

  const ListFeaturesItems = ({ contents }) => {
    return (
      <ul className='ul-1'>
        {contents.map((item, index) => (
          <li className='li-1' key={index}>
            <span>{item.title}:</span> {item.description}
          </li>
        ))}
      </ul>
    );
  };


  const FeatureContent = ({ data }) => {
    return (
      <div className='feature-content-container'>

        <h1>{data.title}</h1>

        <p>{data.content}</p>
      </div>
    );
  };
  const FeaturesMainsContainer = ({ contents }) => {
    return (
      <div className='feature-container'>
        {contents.map((feature, index) => (
          <FeatureContent key={index} data={feature} />
        ))}
      </div>
    );
  }



  const renderXmlFeatures = () => {
    return (
      <div className='feature-xml-container' >
        {homeData.xmlFormatter.map((item, index) => (
          <div key={index} feature-content-container>

            <h1>{item.title}</h1>


            <ul>
              {item.content.map((text, i) => (
                <li key={i}>{text}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    );
  }



  return (
    <div>
      <Helmet>
        <title>{homeData.metaTitle}
        </title>
        <meta name="description" content={homeData.metaDescription} />
      </Helmet>

      <div className='main-containter'>
        <div className='formater-container'>
          <div className='leftads'></div>
          <div className='container-editer-main'>
            <Editor />
          </div>

          <div className='rightads'>

          </div>
        </div>
        <br />
        <br />
        <IntroContainer content={homeData.introData} />
        <br />
        <br />
        <FeaturesMainsContainer contents={homeData.jsonformatter.features1} />
        <br />
        <FeedbackForm />


        <br />
        {renderXmlFeatures()}
        {/* <DonateContainer content={homeData.dontateData}/> */}

        <ListFeaturesItems contents={homeData.jsonformatter.listFeature} />
        <FeaturesMainsContainer contents={homeData.jsonformatter.features2} />
        <br />
        <FeaturesMainsContainer contents={homeData.jsonformatter.features3} />
        <br />
        <FeaturesMainsContainer contents={homeData.jsonformatter.features4} />
        <br />
        <FeaturesMainsContainer contents={homeData.jsonformatter.features5} />
        <br />
        <br />
        <br />
        <br />
      </div>

    </div>
  );
};

export default Home;
