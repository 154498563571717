import React, { useState } from 'react';
import './FeedbackForm.css';

const FeedbackForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const mailData = {
      mailfrom: email,
      mailto: "neofatechnologies@gmail.com",
      mailsubject: `Feedback from ${name}`,
      mailtext: message
    };
    try {
      const response = await fetch( 'https://us-central1-utterrubbish-bf642.cloudfunctions.net/app/sendmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(mailData),
      });

      if (response.ok) {
        alert('Feedback sent successfully!');
        setName('');
        setEmail('');
        setMessage('');
      } else {
        alert('Failed to send feedback. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred. Please try again later.');
    }
  };
  return (
    <div className="feedback-container">
      <h2>Support with your feedback</h2>
      <div className="feedback-content">
        <div className="feedback-options">
          <div className="feedback-option">
            <h3>Report Bug</h3>
            <p>Encounter an issue? Report bugs with detailed information and screenshots to help us resolve them quickly, ensuring a smooth experience for all users.</p>
          </div>
          <div className="feedback-option">
            <h3>New Feature Ideas</h3>
            <p>Have a feature in mind? Share your ideas for new tools or functionalities to help us enhance JSON Formatter and better meet your needs.</p>
          </div>
          <div className="feedback-option">
            <h3> Provide Better Enhancements</h3>
            <p>Suggest improvements for existing features or performance optimizations to help us refine JSON Formatter and make it more powerful and user-friendly.</p>
          </div>
          <div className="feedback-option">
            <h3>Provide Your Rating for This Application</h3>
            <p>Enjoying JSON Formatter? Rate our application to share your experience and help other developers find a reliable JSON formatting tool.</p>
          </div>
        </div>
        <form className="feedback-form" onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <textarea
            placeholder="Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          ></textarea>
          <button type="submit">Send</button>
        </form>
      </div>
    </div>
  );
};

export default FeedbackForm;